import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter,
  useNavigate,
  Link,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";
import Nav from "../Components/Nav";

function Deposit() {
  const [image, setimage] = useState(null);
  const [url, seturl] = useState("");
  const [progress, setprogress] = useState(0);
  const [imagepresnt, setimagepresent] = useState(false);
  const [prevfile, setprevFile] = useState("");
  const [Amount, setAmount] = useState("");
  const [wallet, setwallet] = useState("");

  const [coinselected, setcoinselected] = useState("");
  const [{ userdetails, loggedin, tradingpair }, dispatch] =
    useContext(GlobalContext);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  /// use profits to display earnings too and also
  const [profits, setprofits] = useState(0);

  const [open, setOpen] = React.useState(true);

  const copyAlert = (address) => {
    navigator.clipboard.writeText(address);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: ` Address Was Copied Please Proceed To Payment`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
      setimagepresent(true);
      setprevFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleUpload = () => {
    if (!imagepresnt) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Image Was Selected!",
      });
      return;
    }
    //check wether amt is empty or file is not selected
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            seturl(url);
            updatehistory(url);
            setprogress(0);
            setimagepresent(false);
            // sendAdminMaiil()

            Swal.fire(
              "Successful Transaction!",
              "Your account will be credited once the payment is recieved.",
              "success"
            );
            // alert("we will we credit your balance shortly");
            setimage(null);
            // history("/dashboard")
          });
      }
    );
  };

  const CreatUserObj = async (obj) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    let uobj = {
      fullname: userdetails.fullname,
      email: userdetails.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userid: userids,
      info: obj,
    };

    const userdetail1 = await db
      .collection("deposits")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
  const updatehistory = async (urls) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
    CreatUserObj({
      image: urls,
      date: Date.now(),
      amt: Amount,
      mode: "Deposit",
      coin: wallet,
    });
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      Totaldeposit: increment,
      Deposithistory: firebase.firestore.FieldValue.arrayUnion({
        image: urls,
        date: Date.now(),
        amt: Amount,
        mode: "Deposit",
        coin: wallet,
      }),
    });
  };

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setOpen(!open);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
          setloading(false);
        } else {
          setloggedin(false);
          setOpen(!open);
          navigate("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data());
          setloggedin(true);
          setOpen(!open);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };

  function toggleNav() {
    const nav = document.getElementById("nav");
    let isNavOpen = false;
    const openButton = document.getElementById("open-button");
    const closeButton = document.getElementById("close-button");
    //   nav.classList.toggle("animate-slide");
    nav.classList.toggle("hidden");
    isNavOpen = !isNavOpen;

    openButton.classList.toggle("hidden");
    openButton.classList.toggle("animate-rotate");
    closeButton.classList.toggle("hidden");
    closeButton.classList.toggle("animate-rotate");
  }

  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href="/stylesheets/output.css" />
      <link rel="stylesheet" href="/stylesheets/dashboard.css" />
      <title>Qfsnesere | Dashboard</title>
      {/* SMARTSUPP */}
      {/* Smartsupp Live Chat script */}
      <noscript>
        {" "}
        Powered by &lt;a href=“https://www.smartsupp.com”
        target=“_blank”&gt;Smartsupp&lt;/a&gt;
      </noscript>
      <div className="min-h-screen relative bg-white">
        {/*DASHBOARD NAVIGATION*/}
        <Nav />
        {/* Flash Messages */}
        <div className="flex flex-col flex-wrap justify-center md:flex-row my-10">
          <form
            className="p-2 overflow-hidden sm:rounded-md basis-2/4"
            action="/account/fund-account"
            encType="multipart/form-data"
            method="POST"
          >
            <div className="flex items-center space-x-2 mx-4">
              <p className="text-gray-900 text-3xl md:text-2xl font-semibold">
                Fund Wallet
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-700"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                />
              </svg>
            </div>
            <div>
              <div className="px-4 py-5">
                <div className="grid grid-cols-6 gap-3">
                  <div className="col-span-12">
                    <input
                      type="number"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      name="amount"
                      id="amount"
                      required
                      step="0.000000000000001"
                      min="0.01"
                      placeholder="Enter amount"
                      className="text-black placeholder:text-lg placeholder-zinc-500 w-full bg-transparent font-semibold text-lg mt-1 focus:ring-black focus:border-black block px-3 py-2 border border-gray-800 rounded-md transition duration-300 placeholder:text-gray-500"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="wallet"
                      className="flex justify-between text-lg my-2 font-medium text-gray-700"
                    >
                      Wallet
                    </label>
                    <select
                      name="wallet"
                      onChange={(e) => {
                        setcoinselected(e.target.value);
                      }}
                      id
                      required
                      className="text-black placeholder:text-lg placeholder-zinc-500 w-full bg-transparent font-semibold text-lg mt-1 focus:ring-black focus:border-black block px-3 py-2 border border-gray-800 rounded-md transition duration-300 placeholder:text-gray-500"
                    >
                      <option value>Select Wallet</option>
                      <option value="eth">Eth - (Min 0.01 Eth)</option>
                      <option value="btc">Bitcoin - (Min 0.01 Bitcoin)</option>
                      <option value="doge">Doge - (Min 0.01 Doge)</option>
                      <option value="xlm">XLM - (Min 1 XLM)</option>
                      <option value="xrp">XRP - (Min 0.01 XRP)</option>
                      <option value="Usdc (eth)">
                        Usdc (eth) - (Min 1 Usdc eth)
                      </option>

                      <option value="Usdt(eth)">
                        Usdt(eth) - (Min 0.01 XRP)
                      </option>

                      <option value="Bnb">BNB - (Min 0.01 XRP)</option>

                      <option value="Solana">Solana - (Min 0.01 XRP)</option>
                      {/* <option value="xdc">XDC - (Min 1 XDC)</option> */}
                    </select>
                  </div>
                  {/* <div id="qrcode-container" className="col-span-12 hidden">
                  <img id="qrcode-image" className="mx-auto w-1/3" src="/images/qrcode/btc.jpg" alt="" />
                </div> */}
                  <div id="payment-method-container" className="col-span-12 ">
                    <p className="flex justify-between text-lg my-2 font-medium text-gray-700">
                      Click To Copy Wallet Address
                      <span
                        id="copied-text"
                        className="opacity-0 transition-opacity duration-300"
                      />
                    </p>
                    <div className="grid grid-cols-12 gap-4 items-end col-span-12">
                      {coinselected == "btc" && (
                        <div
                          onClick={() => {
                            copyAlert(
                              "bc1q6vtrp5qm2dtvnp43h5sfpmjzmfpdy6dwh8yxsx"
                            );
                          }}
                          className="payment-method btc col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          BTC
                          <p className="break-all ">
                            bc1q6vtrp5qm2dtvnp43h5sfpmjzmfpdy6dwh8yxsx
                          </p>
                        </div>
                      )}

                      {coinselected == "Usdc (eth)" && (
                        <div
                          onClick={() => {
                            copyAlert(
                              "0x8DF330642D634F8Be205E3F7f908cbEEecEdA69E"
                            );
                          }}
                          className="payment-method Usdc (eth) col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          Usdc (eth)
                          <p className="break-all ">
                            0x8DF330642D634F8Be205E3F7f908cbEEecEdA69E
                          </p>
                        </div>
                      )}

                      {coinselected == "doge" && (
                        <div
                          onClick={() => {
                            copyAlert("DTsEiJzZg876r5L9UKZZ5sjGEmcAgbKiiK");
                          }}
                          className="payment-method doge col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          Doge
                          <p className="break-all ">
                            DTsEiJzZg876r5L9UKZZ5sjGEmcAgbKiiK
                          </p>
                        </div>
                      )}

                      {coinselected == "eth" && (
                        <div
                          onClick={() => {
                            copyAlert(
                              "0x8DF330642D634F8Be205E3F7f908cbEEecEdA69E"
                            );
                          }}
                          className="payment-method eth col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          ETH (ERC20)
                          <p className="break-all ">
                            0x8DF330642D634F8Be205E3F7f908cbEEecEdA69E
                          </p>
                        </div>
                      )}

                      {coinselected == "xrp" && (
                        <div
                          onClick={() => {
                            copyAlert("rL1zdcfjP25E9UwDDDgTCcU9P8rHqSFnps");
                          }}
                          className="payment-method xrp col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          XRP
                          <p className="break-all ">
                            rL1zdcfjP25E9UwDDDgTCcU9P8rHqSFnps
                          </p>
                        </div>
                      )}

                      {coinselected == "xlm" && (
                        <div
                          onClick={() => {
                            copyAlert(
                              "GD4K6IFJCFVRHPN72Y6CGFBSEPUPGV7FT42HCRN6DDM7424COED5EQGF"
                            );
                          }}
                          className="payment-method xlm col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          XLM
                          <p className="break-all ">
                            GD4K6IFJCFVRHPN72Y6CGFBSEPUPGV7FT42HCRN6DDM7424COED5EQGF
                          </p>
                        </div>
                      )}

                      {coinselected == "xdc" && (
                        <div
                          onClick={() => {
                            copyAlert(
                              "bc1qdyhqxjxjqf0aks0yk4cxgjhpxpg5mr7srh9nxg"
                            );
                          }}
                          className="payment-method xdc col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          XDC
                          <p className="break-all ">
                            xdc719e401372e021eced1e41bec6054e7298abb3c5
                          </p>
                        </div>
                      )}

                      {coinselected == "Solana" && (
                        <div
                          onClick={() => {
                            copyAlert(
                              "C2MaxALkJ1jjmVwSbTuf9Vjok3vd5UsTcGodi1ZSFZuP"
                            );
                          }}
                          className="payment-method xdc col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          Solana
                          <p className="break-all ">
                            C2MaxALkJ1jjmVwSbTuf9Vjok3vd5UsTcGodi1ZSFZuP
                          </p>
                        </div>
                      )}

                      {coinselected == "Bnb" && (
                        <div
                          onClick={() => {
                            copyAlert(
                              "0x8DF330642D634F8Be205E3F7f908cbEEecEdA69E"
                            );
                          }}
                          className="payment-method xdc col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          Bnb
                          <p className="break-all ">
                            0x8DF330642D634F8Be205E3F7f908cbEEecEdA69E
                          </p>
                        </div>
                      )}

                      {coinselected == "Usdt(eth)" && (
                        <div
                          onClick={() => {
                            copyAlert(
                              "0x8DF330642D634F8Be205E3F7f908cbEEecEdA69E"
                            );
                          }}
                          className="payment-method xdc col-span-12 border border-gray-800 rounded-md text-black p-4 hover:text-green-600 font-semibold cursor-pointer transition duration-200 "
                        >
                          "Usdt (eth)
                          <p className="break-all ">
                            0x8DF330642D634F8Be205E3F7f908cbEEecEdA69E
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    type="hidden"
                    name="address"
                    defaultValue
                    id="address"
                    required
                  />
                  <input
                    type="hidden"
                    name="method"
                    defaultValue
                    id="method"
                    required
                  />
                  <div className="col-span-12">
                    <label
                      htmlFor="proof"
                      className="flex justify-between text-lg my-2 font-medium text-gray-700"
                    >
                      Proof Of Payment
                    </label>
                    <input
                      onChange={handleChange}
                      type="file"
                      accept="image/*"
                      name="proof"
                      id="proof"
                      autoComplete="amount"
                      placeholder="Transaction Hash"
                      className="text-black px-2 py-2 placeholder:text-lg placeholder-zinc-500 w-full bg-transparent font-semibold text-lg mt-1 focus:ring-black focus:border-black block border border-gray-800 rounded-md transition duration-300 placeholder:text-gray-500"
                    />
                  </div>
                </div>
              </div>

              {imagepresnt && (
                <>
                  <progress id="file" value={progress} max="100">
                    {" "}
                    {progress}{" "}
                  </progress>
                  <div className="col-span-12 px-4">
                    <button
                      id="submit-btn"
                      type="button"
                      onClick={handleUpload}
                      className="flex justify-between items-center w-full text-white font-semibold py-3 px-8 bg-black text-base transition-all my-2 rounded-full"
                    >
                      Deposit
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 ml-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </button>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
